import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { Menu, Transition } from '@headlessui/react'
import { DotsHorizontalIcon, TrashIcon, FlagIcon, PencilIcon, BanIcon, StarIcon } from '@heroicons/react/solid'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'

const CommentRowOptions = ({ comment, setEditing, setDeleted }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const dataRef = useRef(false)
  const [following, setFollowing] = useState(false)
  const [blocked, setBlocked] = useState(false)

  const flag = () => {
    putpostRequest(`/api/v3/comments/${comment.id}/flag`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Thanks for the report</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Our moderators will have a look and take the necessary actions. We will be in contact with you if needed.</p>
      </div>)
    })
  }

  const deleteComment = () => {
    putpostRequest(`/api/v3/comments/${comment.id}`, 'delete', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setDeleted(true)
    })
  }

  const block = () => {
    putpostRequest(`/api/v3/blocked_users/${comment.userId}/block`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setBlocked(!blocked)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!blocked === true ? 'Blocked' : 'Unblock' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!blocked === true ? 'This person can no longer comment on your submissions, message you, or view your profile' : 'This person is no longer blocked'}</p>
      </div>)
    })
  }

  const follow = () => {
    putpostRequest(`/api/v3/follows/${comment.userId}/follow_user`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this person in your dashboard.' : 'You will no longer see activity about this person'}</p>
      </div>)
    })
  }
  const fetchData = () => {
    if (dataRef.current === true) { return }
    dataRef.current = true

    getRequest(`/api/v3/comments/${comment.id}/options`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setFollowing(jsonData.following)
      setBlocked(jsonData.blocked)
    })
  }

  return <>
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          { open === true ? fetchData() : null /* a goddamn travesty */ }

          <Menu.Button className='-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-200'>
            <span className="sr-only" hidden>Open options</span>
            <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
              <div className="py-1">
                { (comment.userId === currentUser.id) && <>
                  <Menu.Item>
                    <div onClick={() => setEditing(true)} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                      <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span>Edit</span>
                    </div>
                  </Menu.Item>
                </> }
                { (comment.userId === currentUser.id || comment.commentableUserId === currentUser.id) && <>
                  <Menu.Item>
                    <div onClick={deleteComment} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt'>
                      <TrashIcon className="mr-3 h-5 w-5 text-red-400" aria-hidden="true" />
                      <span className='text-red-400'>Delete</span>
                    </div>
                  </Menu.Item>
                </> }
                <Menu.Item>
                  <div onClick={follow} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                    { following && <>
                      <StarIcon className="mr-3 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className='text-green-500'>Stop following this person</span>
                    </> }
                    { !following && <>
                      <StarIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span>Follow this person</span>
                    </> }
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div onClick={block} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                    { blocked && <>
                      <BanIcon className="mr-3 h-5 w-5 text-red-500" aria-hidden="true" />
                      <span className='text-red-500'>Unblock this person</span>
                    </> }
                    { !blocked && <>
                      <BanIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span>Block this person</span>
                    </> }
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div onClick={flag} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                    <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span>Report content</span>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  </>
}

CommentRowOptions.propTypes = {
  comment: PropTypes.object,
  setEditing: PropTypes.func,
  setDeleted: PropTypes.func
}

export default CommentRowOptions
