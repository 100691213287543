import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GiftIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'
import { Switch } from '@headlessui/react'

import useQuery from '@/hooks/useQuery'
import classNames from '@/utils/classNamesLocal'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const GiftRow = (props) => {
  const { award, src, awardName, awardCost, setAward } = props
  const selected = award === awardName
  return (
    <li onClick={() => setAward(awardName)} className="relative text-center">
      <div className={`group block w-full aspect-w-10 aspect-h-7 rounded-lg border border-2 border-cccblue bg-gray-100 bg-opacity-75 ${selected ? 'bg-cccblue' : 'bg-white'} overflow-hidden`}>
        <img src={src} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
        <button type="button" className="absolute inset-0 focus:outline-none">
          <span className="sr-only" hidden>View details for {awardName}</span>
        </button>
      </div>
      <p className="block mt-1 text-sm font-medium text-gray-800 dark:text-gray-200 pointer-events-none">{awardCost}</p>
    </li>
  )
}

const GiftModal = ({ giftableType, giftableId }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [award, setAward] = useState('bronze')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      message: null,
      anonymous: false
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('anonymous', { required: false })
    return () => {
      unregister('anonymous')
    }
  }, [register])

  const gotoCoins = () => {
    window.location.href = '/coins/new'
    // history.push('/coins')
  }

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      selected: award,
      coins_gift: {
        giftable_id: giftableId,
        giftable_type: giftableType,
        anonymous: data.anonymous,
        message: data.message
      }
    }

    putpostRequest('/api/v3/coins/gift', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setCurrentUser({ ...currentUser, coins: jsonData.currentCoins })

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Award sent!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">That person will be informed and you probably just made their day.</p>
      </div>)
      closeModal()
    })
  })
  const anonymous = watch('anonymous')

  return <>
    <button onClick={openModal} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
      <GiftIcon className="h-5 w-5" aria-hidden="true" />
      <span className="text-xs hidden sm:block">Award</span>
    </button>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Give an award!</h2>

        <p>Spend cccCoins to show your appreciation.</p>
      </div>
      <ul className="grid grid-cols-2 mt-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        <GiftRow award={award} setAward={setAward} awardName='microphone' awardCost='1' src='https://ddppjbdexhxzj.cloudfront.net/icons/icon_Spotlight.svg' />
        <GiftRow award={award} setAward={setAward} awardName='bronze' awardCost='100' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_MEDAL_BRONZE.svg' />
        <GiftRow award={award} setAward={setAward} awardName='silver' awardCost='1800' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_MEDAL_SILVER.svg' />
        <GiftRow award={award} setAward={setAward} awardName='gold' awardCost='3500' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_MEDAL_GOLD.svg' />
      </ul>
      <p className='text-sm my-5'>
        { award === 'microphone' && <span>Shows a microphone on the submission or comment for everyone to see! <br /> <br /> Recipient will probably love you for a little while until the feeling wears off.</span> }
        { award === 'bronze' && <span>Shows a bronze medal on the submission or comment for everyone to see! <br /> <br /> Recipient will probably love you for a little while until the feeling wears off.</span> }
        { award === 'silver' && <span>Shows a silver medal on the submission or comment for everyone to see! <br /> <br /> Recipient gets 1 week of the Apprentice Plan and 100 cccCoins.</span> }
        { award === 'gold' && <span>Shows a gold medal on the submission or comment for everyone to see! <br /> <br /> Recipient gets 1 month of the Apprentice Plan and 700 cccCoins.</span> }
      </p>
      <hr />
      <form className='grid grid-cols-4 mt-2'>
        <div className="col-span-4 pb-4">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Send an optional message </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="text" {...register('message', { required: false }) } placeholder="This is the best thing out of all the things, ever." />
              { errors.message && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.message && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          <div className='col-span-3 mt-3'>
            <Switch.Group as="div" className="flex items-center justify-between">
              <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Send anonymously?</span>
                <span className="text-sm text-gray-500">The person will not be told who sent the award</span>
              </Switch.Label>
              <Switch
                checked={anonymous}
                onChange={() => handleUpdateSwitch('anonymous')}
                className={classNames(
                  anonymous ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                )}
              >
                <span className="sr-only" hidden>Send award anonymously</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    anonymous ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>

      </form>
      <div className='grid grid-cols-3 my-4'>
        <a to='/coins' className='flex w-full col-span-3 sm:col-span-2'>
          <div className={'bg-cccblue flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'}>
            <img className='h-10 w-10' src="https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg" />
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <div className="text-gray-900 font-medium hover:text-gray-600"> CccCoins </div>
              <p className="text-gray-500">You have {currentUser.coins} CccCoins</p>
            </div>
          </div>
        </a>
      </div>

      { customError && <div className="pr-3 flex items-center pointer-events-none text-red-500">
        <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />
        {customError}
      </div> }
      <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text='Send Award' />
      <DefaultButton onClick={gotoCoins} text='Buy More CccCoins' />
    </Modal>
  </>
}

export default GiftModal

GiftModal.propTypes = {
  giftableType: PropTypes.string.isRequired,
  giftableId: PropTypes.number.isRequired
}

GiftRow.propTypes = {
  award: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  awardName: PropTypes.string.isRequired,
  awardCost: PropTypes.string.isRequired,
  setAward: PropTypes.func
}
