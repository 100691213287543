import { format, utcToZonedTime } from 'date-fns-tz'
import { addSeconds } from 'date-fns'
// import enUS from 'date-fns/locale/en-US'

/* NOTE: Some formatString options. */
export const FULL_DATE_FORMAT = 'MMMM d, yyyy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
// export const ISO_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const MONTH_ABBREV_FORMAT = 'MMM'
export const DAY_OF_MONTH_FORMAT = 'd'
export const HOUR_MIN_FORMAT = 'h:mma'

export function formattedTime(seconds) {
  var helperDate = addSeconds(new Date(0), seconds)
  return format(helperDate, 'mm:ss')
}

/**
 * @param {string} utcDateString Date string in UTC format.
 * @param {string} timezone Timezone name in this format: "America/New_York".
 * @param {string} formatString Date format for date-fns. For options, see https://date-fns.org/v2.21.1/docs/format
 * @returns {string} Formatted date in user timezone.
 */
export function formatInTimezone(
  utcDateString = new Date().toISOString(),
  timezone,
  formatString
) {
  const date = utcToUserTimezone(utcDateString, timezone)
  return format(date, formatString, { timeZone: timezone })
}

/**
 * @param {string} utcDateString Date string in UTC format.
 * @param {string} timezone Optional: Timezone name in this format: "America/New_York".
 *  Defaults to user timezone from local storage, and finally the browser timezone.
 * @returns {object} A new date object adjusted to the timezone.
 */
export function utcToUserTimezone(utcDateString, timezone) {
  return timezone
    ? new Date(utcToZonedTime(utcDateString, timezone))
    : new Date(utcDateString)
}

export function isPast(utcDateString) {
  return utcDateString > new Date().toUTCString()
}

/**
 * @param {string} startDate Date in UTC the event is going to start.
 * @param {string} endDate Date in UTC the event is going to end.
 * @param {string} dateToCheck Date to check if it falls between start and end.
 * @return {boolean} returns true if date is between start and end
 */
export function isBetweenDates(startDate, endDate, dateToCheck) {
  const date = dateToCheck
    ? Date.parse(new Date(dateToCheck).toUTCString())
    : Date.parse(new Date().toUTCString())

  const start = Date.parse(startDate)
  const end = Date.parse(endDate)

  if (date > end) return false

  return date > start && date < end
}
