import React, { useState, useRef } from 'react'

export const VideoPlayer = ({videoUrl, thumbnailUrl}) => {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const togglePlay = (e) => {
    e.preventDefault()
    if (!loaded) {
      player.current.src = videoUrl
      player.current.load()
      player.current.addEventListener('loadedmetadata', () => {
        player.current.play()
        setPlaying(true)
        setLoaded(true)
      })
    } else {
      if (player.current.paused) {
        player.current.play()
        setPlaying(true)
        player.current.setAttribute('controls', 'controls')
      } else {
        player.current.pause()
        setPlaying(false)
        player.current.removeAttribute('controls')
      }
    }
  }

  return <div>
  <div className="flex text-cccorange hover:text-cccorange-alt justify-center rounded-2xl cursor-pointer relative" onClick={togglePlay}>
    <video ref={player} className='rounded-2xl w-full max-h-72' poster={`${thumbnailUrl}?aspect=4:3`}>
      <source />
    </video>
    { !playing && <div className='absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 opacity-70 rounded-full cursor-pointer w-24 h-24'>
      <svg className='stroke-2 text-ssblue hover:stroke-ssblue-alt w-24 h-24 bg-black w-24 h-24 rounded-2xl' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
      </svg>
    </div> }
  </div>
    <div className='mt-1 flex justify-end text-xs text-gray-600 dark:text-gray-400'>Video created by <a href='https://www.videomancy.com' className='ml-1'>Videomancy</a></div>
  </div>
}
