import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import { useGlobalState } from '@/state'

// https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
// good source on lazyloading tippy content

const ToolTip = (props) => {
  const { children } = props
  return (
    <div className="shadow-lg mt-5 md:mt-0 md:col-span-2 border-2 rounded border-cccorange dark:border-cccpurple-alt">
      <div className="p-4 bg-white dark:bg-gray-800 dark:text-gray-200 text-gray-800 sm:rounded-md sm:overflow-hidden">
        { React.Children.map(children, (child) => {
          return (child && <> { child } </>)
        })}
      </div>
    </div>
  )
}

const innards = (children) => {
  return React.Children.map(children, (child) => {
    return (child && <> { child } </>)
  })
}

const TippyTooltip = (props) => {
  const { children, content, interactive } = props
  const [tippyTarget] = useGlobalState('tippyTarget')

  return <Tippy singleton={tippyTarget} placement='top' animation="scale" interactive={interactive} interactiveBorder={0} delay={[800, 0]} content={<ToolTip>{content}</ToolTip>}>
    <span>{innards(children)}</span>
  </Tippy>
}

export { ToolTip, TippyTooltip }

ToolTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}

TippyTooltip.propTypes = {
  content: PropTypes.object,
  interactive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}
