import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import PrimaryButton from '@/shared/Buttons/Primary'
import TipTapTextarea from '@/shared/TipTapTextarea'
import DefaultButton from '@/shared/Buttons/Default'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'

const CommentForm = ({ existingComment, commentableId, commentableType, parentCommentId, setParentComment, setHide, addChildtoParent }) => {
  const tipTapIdx = useRef(1)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      body: existingComment?.body || ''
    }
  })

  const { isDirty } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    let action = 'POST'
    let url = '/api/v3/comments'

    if (existingComment?.id) {
      action = 'PATCH'
      url = `/api/v3/comments/${existingComment.id}`
    }
    const formData = {
      body: data.body,
      parent_id: parentCommentId,
      commentable_type: commentableType,
      commentable_id: commentableId
    }

    putpostRequest(url, action, { comment: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      console.log(jsonData)

      if (action === 'POST') {
        addChildtoParent(jsonData)
      } else {
        setParentComment({ ...existingComment, body: jsonData.body })
      }

      reset({ body: ' ' }) // resetting this without this hack kills froala somehow
    })
  })

  const body = getValues().body

  return <>
    <div className='mt-2 space-y-2'>
      <TipTapTextarea
        html={body}
        idx={tipTapIdx.current}
        updateHtml={(val) => handleUpdateTipTap('body', val)}
        placeholder='You are the best person ever. I love you.'
      />
      <div className='flex space-x-1'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save Comment" />
        <DefaultButton className='ml-2' onClick={() => setHide(false)} text="Cancel" />
      </div>
    </div>
  </>
}

export default CommentForm

CommentForm.propTypes = {
  existingComment: PropTypes.object,
  commentableId: PropTypes.number,
  commentableType: PropTypes.string,
  parentCommentId: PropTypes.number,
  setParentComment: PropTypes.func,
  setHide: PropTypes.func,
  addChildtoParent: PropTypes.func
}
