import React from 'react'
import PropTypes from 'prop-types'

const PageTitle = (props) => {
  const { pagetitle } = props
  return (
    <div className="mt-2 md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:text-3xl sm:truncate">{pagetitle}</h2>
      </div>
      { props.children.length > 0 && <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
        { React.Children.map(props.children, (child) => {
          return (child && <> { child } </>)
        })}
      </div> }
    </div>
  )
}

export default PageTitle

PageTitle.propTypes = {
  pagetitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}
