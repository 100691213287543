export function showNonSpaToast(message, success) {
  const toast = document.getElementById('non-spa-toast')
  const toastMessage = document.getElementById('toast-message')
  const successIcon = document.getElementById('non-spa-toast-success')
  const failureIcon = document.getElementById('non-spa-toast-failure')
  if (!toast) { return }

  toastMessage.innerHTML = message
  if (success) {
    successIcon.classList.remove('hidden')
  } else {
    failureIcon.classList.remove('hidden')
  }
  if (toast.classList.contains('hidden')) {
    toast.classList.add('opacity-100', 'translate-y-0', 'ease-in', 'duration-500')
    toast.classList.remove('hidden', 'translate-y-1', 'ease-out', 'duration-150')
  } else {
    toast.classList.remove('opacity-100', 'translate-y-0', 'ease-in', 'duration-500')
    toast.classList.add('hidden', 'translate-y-1', 'ease-out', 'duration-150')
  }

  setTimeout(hideNonSpatoast, 3000)
}
export function hideNonSpatoast() {
  const toast = document.getElementById('non-spa-toast')
  toast.classList.remove('opacity-100', 'translate-y-0', 'ease-in', 'duration-200')
  toast.classList.add('hidden', 'translate-y-1', 'ease-out', 'duration-150')
  const successIcon = document.getElementById('non-spa-toast-success')
  const failureIcon = document.getElementById('non-spa-toast-failure')
  if (!successIcon.classList.contains('hidden')) { successIcon.classList.add('hidden') }
  if (!failureIcon.classList.contains('hidden')) { failureIcon.classList.add('hidden') }
}
