import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import PrimaryButton from '@/shared/Buttons/Primary'

// TODO: it would be nice to do something like this to normalize 404s throughout the app
// https://www.newline.co/@3nvi/centralizing-api-error-handling-in-react-apps--80296494
/*
const useQuery = ({ url }) => {
  const history = useHistory();
  const [apiData, setApiData] = React.useState();
  React.useEffect(() => {
    fetch(url)
      .then(data => data.json())
      .then(({ code, status, ...apiData }) => {
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          setApiData(apiData);
        }
      });
  }, [url]);
  return { data: apiData }
}
*/

const ErrorHandler = ({ children }) => {
  const location = useLocation()
  const history = useHistory()

  const makeitbetter = () => {
    history.replace(history.location.pathname, { errorStatusCode: 200 })
  }

  // refreshing this page doesnt seem to work, have them click a link instead
  switch (location.state?.errorStatusCode) {
    case 404:
      return <span className='dark:text-gray-200 p-10'>404: couldn't find whatever it was you were looking for</span>
    case 504:
      return <span className='dark:text-gray-200 p-10'>
        <div className='fixed inset-0 z-20 overflow-y-auto'>
          <div className='opacity-70 fixed inset-0 bg-gradient-to-r from-cccpurple via-cccblue to-cccorange' />
          <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className='container mx-auto'>
              <div className='inline-block w-full max-w-xl p-6 my-8 text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-700 dark:text-gray-200 shadow-xl rounded-2xl'>
                <div className=' flex flex-col space-y-3'>
                  <div>Your session has expired.</div>
                  <div>Please click the button to get a hot 'n fresh session, right out of the oven.</div>
                  <PrimaryButton onClick={makeitbetter} text="I'm the Session Refresh Button. Click me!" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    default:
      return children
  }
}

export default ErrorHandler
