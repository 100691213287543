import { useState } from 'react'

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [willClose, setWillClose] = useState(false)

  function openModal() {
    setIsOpen(true)
    setWillClose(false)
  }
  function closeModal() {
    setIsOpen(false)
    setWillClose(true)
  }

  return {
    openModal,
    closeModal,
    willClose,
    isOpen
  }
}

export default useModal
