import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Transition } from '@headlessui/react'
import { XIcon, EmojiHappyIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'

function getSuccessMessage() {
  return successMessages[Math.floor(Math.random() * successMessages.length)]
}

function randomToastSuccess() {
  return (
    <>
      <div className="flex-shrink-0">
        <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Save successful</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500"><i>Deep Thought</i>: {getSuccessMessage()}</p>
      </div>
    </>
  )
}

function achievement(ach) {
  return (
    <>
      <div className="flex-shrink-0">
        <img src={ach.imageUrl} className='h-6 w-6' />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Achievement earned!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500"><i>{ach.name}</i>: {ach.description}</p>
      </div>
    </>
  )
}

const successMessages = [
  'Technically, you can\'t skip breakfast',
  'What #IntermittentFasting means to me: 16 hours of no donuts, and then only donuts',
  'Most leadership skills are just common sense and instinct that you\'ve said out loud.',
  'The most important meal of the day is caffeine.',
  'It\'s with a sad heart that I\'m announcing my cat does not care about me',
  'As a society, we agreed it\'s super weird to brush your teeth in a parking lot',
  'I love toast. What genius took a bite of bread and was like "no, cook it again."',
  'Once you finish school, life changes from a linear to an open-world game.',
  'Headphones are drinking straws for audio',
  'Jurassic Park’s main lesson is about the dangers of mistreating the tech guy.',
  'No-one wants a used mattress for free but we pay hundreds of dollars to sleep on one at hotels.',
  'If it weren’t for clocks on stoves, almost nobody would know if the power went out during the night',
  'Santa knows where all the bad people are, but does not tell the authorities or try to stop them.',
  'During a nuclear explosion, there is a certain distance of the radius where all the frozen supermarket pizzas are cooked to perfection.',
  'Please do not remove the cartridge from the game console.',
  'It kinda makes sense that the target audience for fidget spinners lost interest in them so quickly',
  'Lamps in videogames use real electricity.',
  'Biting your tongue while eating is a perfect example of how you can still screw up, even with decades of experience.'
]

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className='h-1 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }}
        className={`h-full ${progressPercentage < 50 ? 'bg-cccorange' : 'bg-cccpurple'}`}>
      </div>
    </div>
  )
}

function Toast() {
  const [toast, setToast] = useGlobalState('toast')
  const toastMessage = useRef(null)
  const show = toast !== null
  const [perc, setPerc] = useState(0)
  const showtimer = useRef(null)
  const perctimer = useRef(null)

  const pause = () => { clear() }

  const resume = () => {
    showtimer.current = setTimeout(() => setToast(null), perc * 48)
    setPerc(perc - 1)
  }

  const clear = () => {
    window.clearInterval(showtimer.current)
    window.clearInterval(perctimer.current)
  }

  useEffect(() => {
    if (!show) {
      clear()
      return
    }
    if (perc < 100) { // a toast is happening or is done
      clear()
      toastMessage.current = toast
      showtimer.current = setTimeout(() => setToast(null), 5000)
      setPerc(100)
    }
  }, [toast])

  useEffect(() => {
    perctimer.current = perc > 0 && setTimeout(() => setPerc(perc - 1), 40)
  }, [perc])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="fixed inset-0 z-30 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start" >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
              onMouseEnter={pause}
              onMouseLeave={resume}
            >
              <ProgressBar progressPercentage={perc} />
              <div className="p-4">
                <div className="flex items-start dark:text-gray-100">
                  {toastMessage.current}
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white dark:bg-gray-800 rounded-md inline-flex dark:text-gray-100 text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => { setToast(null) }}
                    >
                      <span className="sr-only" hidden>Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired
}

export { Toast, randomToastSuccess, achievement }
