import React from 'react'
import PropTypes from 'prop-types'
import Loading from '@/shared/Loading'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'

const PublicDemoRow = ({ demo }) => {
  return <>
    <div className='bg-cccblue bg-opacity-20 p-4 rounded-lg w-full'>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
          { demo.kind === 'Audio' && <>
            <div className="flex-shrink-0 bg-cccorange rounded-full p-1 mr-2 mb-2">
              { demo.filePathProcessing && <Loading noMessage noLoadingMessage /> }
              { !demo.filePathProcessing && <>
                <PlayPauseButton
                  mediaUrl={demo.audioUrl}
                  objectName={demo.name}
                  objectId={demo.id}
                  objectKind='Demo'
                />
              </> }
            </div>
          </> }
          <div className="min-w-0 flex-auto px-0 md:gap-4">
            <div>
              <p className="font-medium text-cccpurple truncate">{demo.name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap">
        {demo.labelings.map((labeling, idx) => (
          <span key={`la${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
        ))}
      </div>

      { demo.kind === 'Link' && <>
        <p className='truncate mt-1'>
          <a target="_blank" href={demo.remoteUrl} className="text-cccblue hover:text-cccblue-alt" alt={demo.remoteUrl} rel="noreferrer"> Link to document &rarr; </a>
        </p>
      </> }

      { (demo.kind === 'YouTube Video' || demo.kind === 'Loom Video') && <>
        <div className="min-w-0 flex-1 mt-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
          {demo.remoteUrl.includes('loom.com') && <>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }} className='w-full'>
              <iframe src={`${demo.remoteUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              ></iframe>
            </div>
          </>}
          {demo.remoteUrl.includes('youtube.com') && <>
            <iframe width="560" height="315" src={demo.remoteUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

          </>}
        </div>
      </> }
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: demo.description }} />
    </div>
  </>
}

export default PublicDemoRow

PublicDemoRow.propTypes = {
  demo: PropTypes.object.isRequired
}
