import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'

const TipTapTextarea = ({ html, updateHtml, idx, placeholder }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({ openOnClick: false }),
      Placeholder.configure({ placeholder: placeholder })
    ],
    onUpdate({ editor }) {
      updateHtml(editor.getHTML())
    },
    editorProps: {
      handleDOMEvents: {
        keydown: (view, event) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
            event.preventDefault()
            return false
          }
        }
      },
      attributes: {
        class: 'textarea p-2 text-xs sm:text-sm dark:text-gray-100 prose prose-sm' // outline color is in application_v3.scss
      }
    },
    content: html
  })

  useEffect(() => {
    if (idx > 1) { editor?.commands.clearContent(true) }
  }, [idx])

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 rounded-md">
      <EditorContent editor={editor} />
    </div>
  )
}

export default TipTapTextarea

TipTapTextarea.propTypes = {
  html: PropTypes.string,
  updateHtml: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  idx: PropTypes.number
}
