import React from 'react'
import { TippyTooltip } from '@/shared/ToolTip'
import { SVGIcon } from '@/components/icon'

import { useGlobalState } from '@/state'

const EducationCard = () => {
  const [currentUser] = useGlobalState('currentUser')
  /*
  return (
    <a href={`https://www.closingcredits.com?cccu=${currentUser?.id}`}>
      <video autoPlay loop muted className="w-auto h-72 p-2" >
        <source src="https://clc-content.s3.amazonaws.com/static/sale.mp4" type="video/mp4" />
      </video>
    </a>
  )
  */
  return (
    <div className="mt-auto space-y-1 px-2 py-2 flex flex-col m-1  items-center bg-white dark:bg-gray-800 rounded-md mb-0">
      <h6 className='text-center mb-1 dark:text-white'>Now Enrolling </h6>
      <div className='flex items-center'>
        <TippyTooltip content={<>Audio Engineering! Demo Production!</>}>
          <SVGIcon name='audio-engineer' width='30' height='30' autoDark/>
        </TippyTooltip>
        <TippyTooltip content={<>Music Composition for video games, movies, videos, etc</>}>
          <SVGIcon name='musiccomposer' width='30' height='30' autoDark/>
        </TippyTooltip>
        <TippyTooltip content={<>Deep dive into the world of voice acting.</>}>
          <SVGIcon name='voiceactor' width='30' height='30' autoDark/>
        </TippyTooltip>
        <TippyTooltip content={<>Manga and web comic creation. Charcter design and more! </>}>
          <SVGIcon name='artist' width='30' height='30' autoDark/>
        </TippyTooltip>
        <TippyTooltip content={<>Life doesn't give you a script. Improv is training for life!</>}>
          <SVGIcon name='director' width='30' height='30' autoDark/>
        </TippyTooltip>
      </div>
      <a href='https://www.castingcall.club/links?march=clc' className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange" >
        <span>Reserve Your Seat</span>
      </a>
    </div>
  )
}

export default EducationCard
