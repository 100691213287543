import React from 'react'
import { useGlobalState } from '@/state'

import MainNavSidebarItem from '@/Layout/MainNavSidebarItem'

export default function Sidebar () {
  // eslint-disable-next-line no-unused-vars
  const [currentUser] = useGlobalState('currentUser')

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4 dark:bg-gray-800 bg-white">
            <a href='/homepage'>
              <img className="h-8 w-48" src="https://ddppjbdexhxzj.cloudfront.net/logos/casting_call_club_logo.svg" alt="Casting Call Club" />
            </a>
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto dark:bg-gray-700 bg-gray-100">
            <nav className="flex-1 pr-2 py-4">
              <div>
                <MainNavSidebarItem color='orange' name='Open Projects' url='/' customIcon='icon-open-projects' />
                <MainNavSidebarItem color='orange' name='Talent Search' url='/talent' customIcon='icon-talent-search' />
                <MainNavSidebarItem color='orange' name='Dashboard' url='/dashboard' customIcon='icon-home' />
                <MainNavSidebarItem color='orange' name='Messages' url='/private_messages' customIcon='icon-messages' />
                <MainNavSidebarItem color='orange' name='My Profile' url={`/${currentUser.username}`} customIcon='icon-profile' />
                <MainNavSidebarItem color='orange' name='Production Credits' url='/credits' customIcon='icon-credits' />
                <MainNavSidebarItem color='orange' name='Premium Perks' url='/perks' customIcon='icon-perks' />
              </div>
              <div className="mt-5">
                <p className="px-3 mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider">For Talent</p>
                <MainNavSidebarItem color='purple' name='My Submissions' url='/submissions' customIcon='icon-submissions' />
                <MainNavSidebarItem color='purple' name='My Roles' url='/myroles' customIcon='icon-my-roles' />
                <MainNavSidebarItem color='purple' name='Saved Searches' url='/saved_searches' customIcon='icon-saved-searches' />
                <MainNavSidebarItem color='purple' name='Favorites' url='/favorites' customIcon='icon-favorites' />
              </div>
              <div className="mt-5">
                <p className="px-3 mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider">For Talent Seekers</p>
                <MainNavSidebarItem color='blue' name='Manage Projects' url='/manage/projects' customIcon='icon-manage-projects' />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
