import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { LocationMarkerIcon, CalendarIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'

import { TippyTooltip } from '@/shared/ToolTip'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'

const UserHoverCard = ({ username, displayName }) => {
  const { putpostRequest, getRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const dataRef = useRef(false)
  const [following, setFollowing] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (dataRef.current === true) { return }
    dataRef.current = true

    setLoading(true)
    getRequest(`/api/v3/users/${username}/hover_card`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setUser(jsonData.user)
      setFollowing(jsonData.currentUserIsFollower)
    })
  }

  const follow = () => {
    putpostRequest(`/api/v3/follows/${user.id}/follow_user`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this person in your dashboard.' : 'You will no longer see activity about this person'}</p>
      </div>)
    })
  }

  return <>
    <TippyTooltip interactive={true} content={
      <div className="mx-auto rounded-lg bg-white text-gray-800 dark:text-gray-300 dark:bg-gray-800">
        { loading && <Loading /> }
        { !loading && user && <>
          <div className="w-full flex mb-4">
            <div className="overflow-hidden rounded-sm w-12 h-12">
              <img src={user.publicImageUrl} alt="" />
            </div>
            <div className="pl-3 flex flex-col">
              <h6 className="font-bold text-md dark:text-gray-100">{user.displayName}</h6>
              <a href={`/${username}`} className="text-xs">@{user.username}</a>
            </div>
          </div>
          { user.isStaff && <div className='inline-flex justify-center p-1 m-1 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccpurple via-cccblue to-cccorange text-white'>CCC Staff Member</div> }
          { user.headline && <div className="mb-4">
            <p className="text-sm line-clamp-2" dangerouslySetInnerHTML={{ __html: user.headline }}></p>
          </div> }
          <div className="w-full space-x-5 grid grid-cols-4">
            {user.location && <span className="text-gray-500 truncate w-24">
              <LocationMarkerIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-sm'> {user.location}</span>
            </span> }

            <span className="text-gray-800 dark:text-gray-100 truncate w-24">
              <CalendarIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-sm text-gray-500'>{user.createdAt}</span>
            </span>
            <a href={`/${username}?tab=Following`} className="text-gray-500 text-sm w-24"><div className='text-lg leading-5 text-gray-800 dark:text-gray-100'>{user.followingCount}</div> Following</a>
            <a href={`/${username}?tab=Followers`} className="text-gray-500 text-sm w-24"><div className='text-lg leading-5 text-gray-800 dark:text-gray-100'>{user.followersCount}</div> Followers</a>
          </div>

          <PrimaryButton onClick={follow} text={following ? 'Unfollow' : 'Follow' } />
        </> }
      </div>
      }>
      <span onMouseEnter={fetchData}>
        <a href={`/${username}`} className="font-medium text-cccblue">{displayName}</a>
      </span>
    </TippyTooltip>
  </>
}

export default UserHoverCard

UserHoverCard.propTypes = {
  username: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
}
