import React from 'react'
import PropTypes from 'prop-types'

const statusPillStyle = {
  default: 'px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800 capitalize',
  error: 'px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize',
  success: 'px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize',
  new: 'flex-shrink-0 inline-block px-2 mr-1 mb-1 text-white bg-red-500 text-xs font-medium rounded-full flex cursor-pointer',
  search: 'flex-shrink-0 inline-block px-2 mr-1 mb-1 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer',
  warning: 'px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 capitalize'
}

const StatusPill = ({ status, text }) => {
  return <span className={statusPillStyle[status]}>{text} </span>
}

export default StatusPill

StatusPill.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
