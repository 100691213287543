import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { XCircleIcon } from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import classNames from '@/utils/classNamesLocal'

export const Modal = (props) => {
  const { isOpen, closeModal, full } = props

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={closeModal} >
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Dialog.Overlay className="opacity-70 fixed inset-0 bg-gradient-to-r from-cccpurple via-cccblue to-cccorange" />

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span> */}

          <div className='container mx-auto'>
            <div className={classNames(full ? 'min-w-full' : '', 'inline-block w-full max-w-xl p-6 my-8 text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-700 dark:text-gray-200 shadow-xl rounded-2xl')}>
              <div className='relative mb-1'>
                <button className="p-1 transition-colors duration-200 transform rounded-md hover:bg-opacity-25 focus:outline-none absolute -top-5 -right-5" type="button" aria-label="Close" aria-hidden="true">
                  <span className="sr-only" hidden>Close Modal</span>
                  <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 hover:text-cccblue dark:hover:text-cccblue text-gray-500 dark:text-gray-400' />
                </button>
              </div>
              { React.Children.map(props.children, (child) => {
                return (child && <> { child } </>)
              })}
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  full: PropTypes.bool
}
