import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { ChatAltIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom'

import CommentRowOptions from '@/pages/Users/PublicProfile/PublicProfileCommentRowOptions'
import CommentForm from '@/shared/CommentForm'
import UserHoverCard from '@/shared/UserHoverCard'

const DeletedCommentRow = ({ comment }) => {
  const children = comment.comments || []
  if (children.length === 0) { return null }

  return <>
    <div className={`relative ${comment.parentId ? 'pl-7' : 'pl-0'} pt-2`}>
      <span className={`absolute ${comment.parentId ? 'left-10' : 'left-3'} -ml-px h-full w-0.5 bg-opacity-20 bg-cccorange dark:bg-cccpurple`} aria-hidden="true" />
      <div className="relative flex items-start space-x-3">
        <div className="relative">
          <ChatAltIcon className="h-6 w-6 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" aria-hidden="true" />
        </div>
        <div className="flex-1 md:py-0 pb-2">
          <div className="mt-2 text-sm text-gray-700 dark:text-gray-300 space-y-4">[deleted]</div>
        </div>
      </div>
      { children.length > 0 && <ul className="space-y-3 mt-2">
        {children.map((com, idx) => (
          <CommentRow key={`com${com.id}`} initialComment={com} />
        ))}
      </ul> }
    </div>
  </>
}

const CommentRow = ({ initialComment, hideImage }) => {
  const [comment, setComment] = useState(initialComment)
  const history = useHistory()
  if (!comment?.id) { return null }

  useEffect(() => { setEditing(false) }, [comment])

  const [children, setChildren] = useState(comment.comments || [])
  const [editing, setEditing] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [showReplyForm, setShowReplyForm] = useState(false)
  if (comment.body === '[deleted]' || deleted) {
    return <DeletedCommentRow comment={comment} />
  }

  const addChildtoParent = (childComment) => {
    const newChildren = Object.assign([], children)
    newChildren.push(childComment)
    setEditing(false)
    setShowReplyForm(false)
    setChildren(newChildren)
  }

  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }

  return <>
    <div className={`relative ${comment.parentId ? 'pl-7' : 'pl-0'} pt-2`}>
      <span className={`absolute ${comment.parentId ? 'left-10' : 'left-3'} -ml-px h-full w-0.5 bg-opacity-20 bg-cccorange dark:bg-cccpurple`} aria-hidden="true" />
      <div className="relative flex items-start space-x-3">
        <div className="relative">
          <img className="h-6 w-6 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" src={comment.publicImageUrl} alt={comment.displayName} />

          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px dark:bg-gray-800">
            <ChatAltIcon className="h-2 w-2 text-gray-600 dark:text-gray-400" aria-hidden="true" />
          </span>
        </div>
        <div className="min-w-0 flex-1">
          { editing && <CommentForm
            existingComment={comment}
            commentableId={comment.commentableId}
            commentableType={comment.commentableType}
            setParentComment={setComment}
            setHide={setEditing}
          /> }
          { !editing && <div className="flex-1 md:py-0 pb-2">
            <div className="flex justify-between">
              <div className='flex justify-start items-center'>
                <UserHoverCard username={comment.username} displayName={comment.displayName} />
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500 ml-2">
                  <time dateTime={comment.createdAt}>{comment.createdAt}</time>
                </span>
              </div>
              <div>
                <CommentRowOptions
                  comment={comment}
                  setEditing={setEditing}
                  setDeleted={setDeleted}
                />
              </div>
            </div>
            <div onClick={handleContentClick} className="text-sm text-gray-700 dark:text-gray-300 space-y-2" dangerouslySetInnerHTML={{ __html: comment.body }} />
            <div className="mt-2 flex justify-between space-x-8">
              <div className="flex space-x-6">
                <button onClick={() => setShowReplyForm(true)} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                  <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="text-xs">Reply</span>
                </button>
              </div>
            </div>
            { showReplyForm && <CommentForm
              parentCommentId={comment.id}
              commentableId={comment.commentableId}
              commentableType={comment.commentableType}
              addChildtoParent={addChildtoParent}
              setHide={setShowReplyForm}
            /> }
          </div> }
        </div>
      </div>
      { children.length > 0 && <ul className="space-y-3 mt-2">
        {children.map((com, idx) => (
          <CommentRow key={`com${com.id}`} initialComment={com} />
        ))}
      </ul> }
    </div>
  </>
}

export default CommentRow

CommentRow.propTypes = {
  initialComment: PropTypes.object,
  hideImage: PropTypes.bool
}

DeletedCommentRow.propTypes = {
  comment: PropTypes.object
}
