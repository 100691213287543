import React from 'react'
import PropTypes from 'prop-types'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'

const Breadcrumbs = (props) => {
  return (
    <div className='pt-6'>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-1 sm:space-x-4">
          <li>
            <div>
              <a href="/" className="text-xs sm:text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
                <HomeIcon className="flex-shrink-0 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          { React.Children.map(props.children, (child) => {
            return (child && <li className="flex items-center space-x-1">
              <ChevronRightIcon className="flex-shrink-0 h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" />

              { child }
            </li>)
          })}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}
