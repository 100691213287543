import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { showNonSpaToast } from '@/utils/nonSpaToast'
import { Menu, Transition } from '@headlessui/react'
import {
  MicrophoneIcon, UserCircleIcon,
  DotsHorizontalIcon,
  FlagIcon,
  BanIcon,
  FilmIcon,
  ArrowSmDownIcon
} from '@heroicons/react/solid'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'

const SubmissionRowOptions = ({ submission, setDeleted }) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser,] = useGlobalState('currentUser')
  const { putpostRequest } = useQuery()
  const flag = () => {
    putpostRequest(`/api/v3/auditions/${submission.id}/flag`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Thanks for the report</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Our moderators will have a look and take the necessary actions. We will be in contact with you if needed.</p>
      </div>)
    })
  }

  const destroy = () => {
    setDeleted(true)
    putpostRequest(`/api/v3/auditions/${submission.id}/soft_delete`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      showNonSpaToast('Deleted. It will be missed.', true)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Deleted</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">It will be missed.</p>
      </div>)
    })
  }

  return <>
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-200">
          <span className="sr-only" hidden>Open options</span>
          <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
          <div className="py-1">
            <Menu.Item>
              <a href={`/projects/${submission.projectSlug}`} className='text-gray-700 flex px-4 py-2 text-sm' >
                <FilmIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>View Project</span>
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href={`/projects/${submission.projectSlug}#jumptorole${submission.roleId}`} className='text-gray-700 flex px-4 py-2 text-sm' >
                <UserCircleIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>View Project Role</span>
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href={`/auditions/${submission.id}`} className='text-gray-700 flex px-4 py-2 text-sm' >
                <MicrophoneIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>View This Submission</span>
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href={submission.rawAudioUrl} target="_blank" rel="noreferrer" className='text-gray-700 flex px-4 py-2 text-sm' >
                <ArrowSmDownIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Download Raw Audio</span>
              </a>
            </Menu.Item>
            <Menu.Item>
              <div onClick={flag} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Report content</span>
              </div>
            </Menu.Item>
            { currentUser?.id === submission?.userId && <Menu.Item>
              <div onClick={destroy} className='text-gray-700 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt' >
                <BanIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Delete</span>
              </div>
            </Menu.Item> }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </>
}
SubmissionRowOptions.propTypes = {
  setDeleted: PropTypes.func.isRequired,
  submission: PropTypes.object
}

export default SubmissionRowOptions
