import React from 'react'
import PropTypes from 'prop-types'

import { SVGIcon } from '@/components/icon'
import { useGlobalState } from '@/state'
import Loading from '@/shared/Loading'

const PlayPauseButton = (props) => {
  const { mediaUrl, objectId, objectKind } = props
  const [mediaObject, setMediaObject] = useGlobalState('mediaObject')
  const [playing, setPlaying] = useGlobalState('playing')
  const [loadingMedia] = useGlobalState('loadingMedia')
  const [, setToast] = useGlobalState('toast')

  const play = () => {
    if (mediaUrl === null) {
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">There is no audio file associated. </p>
      </div>)
      return
    }

    if (mediaObject.mediaUrl === mediaUrl && mediaObject.objectId === objectId) {
      setPlaying(true)
    } else {
      setMediaObject({
        mediaUrl: mediaUrl,
        objectId: objectId,
        objectKind: objectKind
      })
    }
  }

  const pause = () => {
    setPlaying(false)
  }

  const loadingThis = loadingMedia && mediaUrl === mediaObject.mediaUrl

  if (mediaObject.mediaUrl === mediaUrl && mediaObject.objectId === objectId && playing) {
    return <>
      <div onClick={pause} aria-label='Pause' className="cursor-pointer flex-shrink-0 bg-cccorange rounded-full p-1">
        <SVGIcon name='pause' width='36' height='36' autoDark/>
      </div>
    </>
  }

  return <>
    <div onClick={play} aria-label='Play' className="cursor-pointer flex-shrink-0 bg-cccorange rounded-full p-1 h-11 w-11">
      { loadingThis && <Loading noMessage noLoadingMessage player/> }
      { !loadingThis && <SVGIcon name='play' width='36' height='36' autoDark/> }
    </div>
  </>
}

export default PlayPauseButton

PlayPauseButton.propTypes = {
  mediaUrl: PropTypes.string,
  objectId: PropTypes.number.isRequired,
  objectKind: PropTypes.string.isRequired
}
