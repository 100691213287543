import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'

import { Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className='h-1 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }}
        className={`h-full ${progressPercentage < 50 ? 'bg-cccorange' : 'bg-cccpurple'}`}>
      </div>
    </div>
  )
}

const HEARTTIMER = 5 * 60 * 1000
const IDLETIMER = 15 * 60 * 1000

export default function SiteNotification() {
  const heartbeatTimer = useRef(null)
  const idleTimer = useRef(null)
  const [releaseInfo] = useGlobalState('releaseInfo')
  const [, setNotificationsCounts] = useGlobalState('notificationCounts')
  const [show, setShow] = useState(false)
  const { getRequest } = useQuery()

  const wentIdle = () => { setShow(true) }

  const heartbeat = () => {
    if (show) { return }

    window.clearInterval(idleTimer.current)

    getRequest('/api/v3/notifications/heartbeat', {}, (err, jsonData) => {
      if (err) {
        setShow(true)
        return
      }
      if (releaseInfo !== jsonData.releaseInfo) {
        setShow(true)
      }
      setNotificationsCounts(jsonData.notificationCounts)

      heartbeatTimer.current = setTimeout(() => heartbeat(), HEARTTIMER)
      idleTimer.current = setTimeout(() => wentIdle(), IDLETIMER)
    })
  }

  useEffect(() => { heartbeat() }, [])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="fixed inset-0 z-30 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5" >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium dark:text-yellow-600 text-yellow-500">Out of Date Version</p>
                    <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">
                    You are using an old version of Casting Call Club and if you run an older version, you might experience bugs. <a className="text-cccblue cursor-pointer" onClick={() => { window.location.reload() }}>Click here to reload the page and get the latest version.</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired
}
